const list = [
  "Middle East",
  "Africa",
  "Far East and Asia",
  "Eroupe",
  "South America",
  "Others",
];

export default list;
