const list = [
  "National Curriculum for England and Wales",
  "USA",
  "Canadian",
  "Australian",
  "New Zealand",
  "IB",
];

export default list;
