const list = [
  "Australia",
  "Canada",
  "EU",
  "Irish",
  "New",
  "Other",
  "South",
  "UK",
  "USA",
];

export default list;
