const list = [
  "Arabic",
  "Art ",
  "Biology",
  "Business Studies",
  "Chemistry",
  "Design Technology",
  "Drama",
  "Early Years",
  "Economics ",
  "Elementary/Primary",
  "English Language/Literature",
  "English as Second Language",
  "Food Technology",
  "French",
  "General Science",
  "Geography",
  "German",
  "Global Perspectives",
  "Headteacher/Principal",
  "History",
  "Humanities",
  "IB Coordinator",
  "ICT Information Technology",
  "Learning Support",
  "Librarian",
  "Mandarin",
  "Mathematics",
  "Media Studies",
  "Middle Management/Coordinator",
  "Music",
  "MYP Coordiantor",
  "Other",
];

export default list;
